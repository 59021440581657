<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: Estefania Villada Chavarria                                   ###### -->
<!-- ###### @date: Noviembre 2023                                                  ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
    <div class="contenido">
        <!-- Filtros para buscar relaciones -->
        <section class="d-flex">
            <!-- Filtrar por Usuario -->
            <v-text-field class="me-2 filtro" v-model="buscar.usuario" label="Usuario" outlined
                dense hide-details @keypress="validarLetras">
            </v-text-field>
            <!-- Filtrar por Estados auditoria -->
            <v-text-field class="me-2 filtro" v-model="buscar.auditoria" label="Estado auditoria" outlined
                dense hide-details @keypress="validarLetras">
            </v-text-field>
            <!-- Filtrar por estado-->
            <v-select class="me-2 filtro" v-model="buscar.estado" label="Estado" outlined
                dense hide-details :items="estados" :menu-props="{ offsetY: true }">
            </v-select>
            <!-- Este botón abre un diálogo para agregar una relación -->
            <div>
                <v-tooltip left color="success" :open-on-focus="false">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn small fab color="success" v-bind="attrs" v-on="on"
                            @mousedown.prevent="dialogoRelacion = true">
                            <v-icon> add </v-icon>
                        </v-btn>
                    </template>
                    <span>Agregar</span>
                </v-tooltip>
            </div>
        </section>

        <!-- Diálogo para crear una relacion -->
        <v-dialog v-model="dialogoRelacion" transition="dialog-bottom-transition" max-width="30rem" persistent>
            <v-card>
                <v-card-title class="encabezado">
                    <span class="text-h6">Crear relación</span>
                </v-card-title>
                <v-card-text class="dialogo">
                    <validation-observer ref="observer" v-slot="{ invalid }">
                        <!-- Formulario para registrar la relación -->
                        <v-form lazy-validation>
                            <!-- Campo para ingresar el usuario -->
                            <div class="d-flex filas me-2">
                                <validation-provider v-slot="{ errors }" ref="usuarioValidation" :rules="{ required: true }"
                                    class="campos">
                                    <v-autocomplete v-model="crearRelacion.usuario" label="Usuario" required dense outlined
                                        :error-messages="errors" :items="opciones.usuarios" 
                                        :menu-props="{ maxHeight: 200, offsetY: true, maxWidth: 453 }"
                                        @input="validarRelacionExistente()" @click="listarUsuarios()">
                                    </v-autocomplete>
                                </validation-provider>
                            </div>
                            <!-- Campo para ingresar el estado auditoría -->
                            <div class="d-flex filas me-2">
                                <validation-provider v-slot="{ errors }" ref="estadoValidation"
                                    :rules="{ required: true }" class="campos">
                                    <v-autocomplete v-model="crearRelacion.auditoria" label="Estado auditoría"
                                        :error-messages="errors" :items="opciones.estados" dense outlined 
                                        :menu-props="{ maxHeight: 200, offsetY: true, maxWidth: 453 }"
                                        @input="validarRelacionExistente()" @click="listarAuditorias()">
                                    </v-autocomplete>
                                </validation-provider>
                            </div>
                            <div class="d-flex justify-end">
                                <v-btn class="me-2" text color="error" @click="clear()">Cerrar</v-btn>
                                <v-btn color="success" :disabled="invalid || botonValidacion" text depressed 
                                    @click="agregarRelacion()">
                                    Guardar
                                </v-btn>
                            </div>
                        </v-form>
                    </validation-observer>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- Tabla de los registros usuario-auditoria creados -->
        <v-data-table class="elevation mt-4" :items="tablaRelacion.items" fixed-header :headers="tablaRelacion.headers"
            :loading="tablaRelacion.loading" :page.sync="tablaRelacion.page" :server-items-length="tablaRelacion.totalPage"
            :footer-props="tablaRelacion.footerProps" :items-per-page.sync="tablaRelacion.itemsPerPage" height="64vh">
            <template v-slot:body="{ items }">
                <tbody>
                    <tr v-for="item in items" v-bind:key="item.idUsau">
                        <td> {{ item.nombre }} </td>
                        <td> {{ item.descripcionAuditoria }} </td>
                        <td>
                            <v-icon :color="item.eliminado === false ? 'success' : 'error'">
                                {{ item.eliminado === false ? 'check_circle' : 'cancel' }}
                            </v-icon>
                            {{ item.eliminado === false ? 'Activo' : 'Inactivo' }}
                        </td>
                        <td class="text-center">
                            <v-btn icon @click="abrirDialogoEstado(item)">
                                <v-icon :color="item.eliminado === false ? 'error' : 'success'">
                                    {{ item.eliminado === false ? 'person_add_disabled' : 'how_to_reg' }}
                                </v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </tbody>
            </template>
            <template v-slot:footer.page-text="items">
                {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
            </template>
        </v-data-table>

        <!-- dialogo para cambiar el estado -->
        <v-dialog v-model="dialogoCambiarEstado" transition="dialog-bottom-transition" max-width="25rem" persistent>
            <v-card>
                <v-card-title class="encabezado">
                    <span class="text-h6">
                        {{ mensajeEstado }}
                    </span>
                </v-card-title>
                <v-card-text class="dialogo">
                    <div class="d-flex justify-end">
                        <v-btn class="me-2" color="error" text @click="dialogoCambiarEstado = false">No</v-btn>
                        <v-btn color="success" depressed text @click="cambiarEstado()">Si</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

    </div>
</template>
<!-- #################################################################################### -->
<!-- ###### Sección de Script                                                      ###### -->
<!-- #################################################################################### -->
<script>
import { mapMutations, mapState } from "vuex";
import { Role } from "@/router/role.js";
import { required } from 'vee-validate/dist/rules';
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate';

// validaciones con vee-validate
setInteractionMode('eager')
extend('required', {
    ...required,
    message: 'Este campo es requerido.',
});
// validaciones con vee-validate---fin
export default {
    name: "UsuarioAuditoria",
    props: {
    },
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            buscar: {
                usuario: '',
                auditoria: '',
                estado: ''
            },
            estados: [
                { text: 'Todos', value: '' },
                { text: 'Activo', value: false },
                { text: 'Inactivo', value: true },
            ],
            dialogoRelacion: false,
            crearRelacion: {
                usuario: '',
                auditoria: '',
            },
            botonValidacion: false,
            opciones: {
                usuarios: [],
                estados: []
            },
            eliminado: false,
            lastmodifiedby: '',
            tablaRelacion: {
                loading: false,
                items: [],
                headers: [
                    { text: "USUARIO", width: "35%", sortable: false },
                    { text: "ESTADO AUDITORÍA", width: "35%", sortable: false },
                    { text: "ESTADO", width: "15%", sortable: false },
                    { text: "ACCIONES", align: "center", width: "15%", sortable: false },
                ],
                footerProps: {
                    'items-per-page-options': [10, 15, 20, 30],
                    'items-per-page-text': 'Items por página:',
                    'show-current-page': true,
                    'show-first-last-page': true
                },
                itemsPerPage: 10,
                page: 1,
                totalPage: 0
            },
            relacionSeleccionada: {},
            dialogoCambiarEstado: false,
            mensajeEstado: '',
            userRoles: {},
            roles: Role,
        }
    },
    created() {
        this.userRoles = this.auth.roles;
    },
    computed: {
        ...mapState(["auth", "notify", "busy", "enterprise"]),
    },
    watch: {
        'tablaRelacion.page': function () {
            this.listarRelaciones();
        },
        'tablaRelacion.itemsPerPage': function () {
            this.tablaRelacion.page = 1;
            this.listarRelaciones();
        },
        'buscar.usuario': function () {
            this.tablaRelacion.page = 1;
            this.listarRelaciones();
        },
        'buscar.auditoria': function () {
            this.tablaRelacion.page = 1;
            this.listarRelaciones();
        },
        'buscar.estado': function () {
            this.tablaRelacion.page = 1;
            this.listarRelaciones();
        },
    },
    methods: {
        ...mapMutations([
            "updateAuth",
            "hideNotify",
            "showSuccess",
            "showBusy",
            "hideBusy",
        ]),
        /**
         * Lista las relaciones usuario-auditoria en una tabla mediante una solicitud HTTP GET 
         * con los siguientes parámetros:
         * page - página actual
         * size - tamaño de la página o número de elementos por página
         * nombre - filtrar por el nombre del usuario
         * descripcionAuditoria - filtrar por la auditoria
         * eliminado - filtrar por el estado de la relación, puede ser activo o inactivo
         */
        listarRelaciones() {
            this.$http.get(`msa-administration/usuarioAuditoria/listarUsuariosAuditorias`, {
                params: {
                    page: this.tablaRelacion.page -1,
                    size: this.tablaRelacion.itemsPerPage,
                    nombre: `${this.buscar.usuario.toLowerCase()}`,
                    descripcionAuditoria : `${this.buscar.auditoria.toLowerCase()}`,
                    eliminado: `${this.buscar.estado}`,
                    idEmpresa: this.enterprise.code
                }
            }).then((response) => {
                this.tablaRelacion.items = response.data.content,
                this.tablaRelacion.totalPage = response.data.totalElements,
                this.tablaRelacion.loading = false;
            }).catch((error) => {
                console.log(error);
            })
        },
        /**
         * Método que solo permite el ingreso de letras
         * si la tecla presionada no corresponde a la condición, se evita que se ingrese el caracter
         * lo que hace que no se ingrese el valor de la tecla
         * @param {evento} event - El evento del teclado que se produce al escribir en el campo de entrada.
         */
        validarLetras(event) {
            const key = event.key;
            if (!key.match(/[a-zA-ZáéíóúÁÉÍÓÚ .-]/)) {
                event.preventDefault();
            }
        },
        /**
         * Lista las auditorias auditorias para crear una nueva relación.
         */
        listarAuditorias() {
            this.$http.get(`msa-administration/auditoria/listarAuditoria`, {
                params: {
                    idEmpresa: this.enterprise.code,
                    eliminado: false
                }
            })
            .then((response) => {
                response.data.forEach((item) => {
                    this.opciones.estados.push({
                        text: item.descripcionAuditoria,
                        value: item.idAuditoria
                    })
                })
            }).catch((error) => {
                console.log(error);
            })
        },
        /**
         * Lista los usuarios para crear una nueva relación.
         */
        listarUsuarios() {
            this.$http.get(`msa-administration/usuarios/buscarUsuario`, {
                params: {
                    idEmpresa: this.enterprise.code,
                    estado: 'true'
                }
            })
            .then((response) => {
                response.data.forEach((item) => {
                    this.opciones.usuarios.push({
                        text: `${item.usuario} - ${item.nombre}`,
                        value: item.usuario
                    })
                })
            }).catch((error) => {
                console.log(error);
            })
        },
        /**
         * Valida la existencia de una relación usuario-auditoria por medio de una solicitud HTTP GET 
         * con los siguientes parametros:
         * usuario - El id del usuario a buscar.
         * idAuditoria - El id de la auditoria a buscar.
         * Si existe desactiva el botón de guardar y muestra un mensaje de error, de lo contrario permite 
         * guardar la relación.
         */
        validarRelacionExistente() {
            if (this.crearRelacion.usuario && this.crearRelacion.auditoria) {
                this.$http
                    .get("msa-administration/usuarioAuditoria/validarExistencia", {
                        params: {
                            usuario: this.crearRelacion.usuario,
                            idAuditoria: this.crearRelacion.auditoria,
                            idEmpresa: this.enterprise.code
                        }
                    })
                    .then((response) => {
                        if (response.data !== null) {
                            this.$refs.estadoValidation.errors = ["La relación ya existe."];
                            this.botonValidacion = true;
                        } else {
                            this.$refs.estadoValidation.errors = [];
                            this.botonValidacion = false;
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }
        },
        /**
         * Agrega una nueva relación usuario-auditoria enviando una solicitud 
         * HTTP POST con los datos proporcionados.
         */
        agregarRelacion() {
            const relacion = {
                usuarios: {
                    usuario: this.crearRelacion.usuario
                },
                auditoria: {
                    idAuditoria: this.crearRelacion.auditoria
                },
                eliminado: this.eliminado,
                lastmodifiedby: this.lastmodifiedby,
                empresa: {
                    idEmpresa: this.enterprise.code
                }
            }
            this.$http
            .post(`msa-administration/usuarioAuditoria/guardar`, relacion)
            .then(() => {
                this.listarRelaciones();
                this.dialogoRelacion = false;
                this.clear();
            }).catch((error) => {
                console.log(error);
            })
        },
        /**
         * Abre un diálogo para cambiar el estado de una relación seleccionada
         * @param {Object} item - El objeto que representa la relación seleccionada(datos actuales de la relación)
         */
        abrirDialogoEstado(item) {
            this.dialogoCambiarEstado = true
            this.relacionSeleccionada = item;
            this.mensajeEstado = this.relacionSeleccionada.eliminado === false ? '¿Desea inactivar la relación?' : '¿Desea activar la relación?';
            setTimeout(() => {
                this.mensajeEstado = this.relacionSeleccionada.eliminado === false ? '¿Desea inactivar la relación?' : '¿Desea activar la relación?';
            }, 1000);
        },
        /**
         * Cambia el estado de una relación entre false (activo) y true (inactivo) por medio de una 
         * solicitud HTTP PUT y actualiza este cambio en el servidor. Luego, se lista de nuevo las relaciones 
         * y se cierra el diálogo de cambio de estado.
         */
        cambiarEstado() {
            this.relacionSeleccionada.eliminado = (this.relacionSeleccionada.eliminado == false) ? true : false;
            const cambiarEstado = {
                idUsau: this.relacionSeleccionada.idUsau,
                eliminado: this.relacionSeleccionada.eliminado,
                lastmodifiedby: this.lastmodifiedby
            }
            this.$http
            .put(`msa-administration/usuarioAuditoria/actualizarEstado`, cambiarEstado)
            .then(() => {
                this.listarRelaciones();
                this.dialogoCambiarEstado = false
            }).catch((error) => {
                console.log(error);
            })
        },
        /**
         * Método que se encarga de limpiar o restablecer varios valores a su estado inicial.
         */
        clear() {
            this.dialogoRelacion = false;
            this.crearRelacion.usuario = '';
            this.crearRelacion.auditoria = '';
            this.$refs.observer.reset();
            this.opcionesUsuarios = [];
            this.opcionesEstados = [];
            this.botonValidacion = false;
        },
    },
    mounted() {
        this.lastmodifiedby = this.auth.username.toUpperCase();
        this.listarRelaciones();
    },
}
</script>
<!-- #################################################################################### -->
<!-- ###### Sección de Style                                                       ###### -->
<!-- #################################################################################### -->
<style scoped>
.contenido {
    padding: 1rem;
    height: 86vh;
}

.filtro {
    width: 20%;
}

.encabezado {
    background-color: #1867c0;
    color: white;
}

.dialogo {
    padding-top: 20px !important;
}

.filas {
    width: 100% !important;
}

.campos {
    width: 100%;
}

.tooltip {
    height: 2.5rem;
    margin-left: 3px;
}

::v-deep .elevation .v-data-footer {
    width: 100%;
}

::v-deep .elevation div table thead tr th {
    background-color: rgb(223, 223, 223) !important;
}

::v-deep .elevation div table thead tr th span {
    font-weight: bold;
    color: black !important;
}
</style>